// Header.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const Header = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
        logout(() => navigate('/'));
    };

  return (
    <header style={{ padding: 20, background: 'lightgray', display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <Link to="/" style={{ margin: 10 }}>Home</Link>
	<Link to="/dashboard" style={{ margin: 10 }}>Dashboard</Link>
        {user ? (
          <span>Welcome, {user.username}!</span>
        ) : (
          <>
            <Link to="/login" style={{ margin: 10 }}>Login</Link>
            <Link to="/register" style={{ margin: 10 }}>Register</Link>
          </>
        )}
      </div>
      {user && (
        <button onClick={handleLogout} style={{ margin: 10 }}>Logout</button>
      )}
    </header>
  );
};

export default Header;
