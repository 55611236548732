import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';  // Ensure you have the correct path to AuthContext
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://116.203.68.72/api/login', {
        username,
        password,
      });
      if (response.data.token) {
	const decoded = jwtDecode(response.data.token);
        login({ username: decoded.username, token: response.data.token });  // Update context with decoded info
	localStorage.setItem('token', response.data.token);  // Save the token to localStorage
        console.log('Login successful:', response.data);
	navigate('/');  // Redirect to home page
      }
    } catch (error) {
      console.error('Login failed:', error.response?.data || 'No response from server');
    }
  };

  return (
    <form onSubmit={handleLogin}>
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
        required
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        required
      />
      <button type="submit">Login</button>
    </form>
  );
}

export default Login;
