import React from 'react';
import { useAuth } from './AuthContext'; // Ensure the path is correct based on your file structure

const Home = () => {
  const { user } = useAuth();

  return (
    <div style={{ padding: 20 }}>
      <h1>Welcome to Your Stock Portfolio Tracker!</h1>
      {user ? (
        <p>Hello, {user.username}! Check out your latest stock performance.</p>
      ) : (
        <p>Please log in to track your stocks.</p>
      )}
    </div>
  );
};

export default Home;
