import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import Header from './components/Header';
import Home from './Home';
import Dashboard from './Dashboard';
import Login from './Login';
import Register from './Register';

const App = () => {

    const navigate = useNavigate();
    const { setLogoutCallback } = useAuth();

    useEffect(() => {
        setLogoutCallback(() => navigate('/'));
    }, [navigate, setLogoutCallback]);

    return (
        <div>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/dashboard" element={<Dashboard />} />
            </Routes>
        </div>
    );
};

const RootComponent = () => (
  <Router>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Router>
);

export default RootComponent;
