import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const intervalRef = useRef(null);  // Use useRef to hold the interval ID
    const logoutCallbackRef = useRef(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            if (decoded.exp * 1000 < Date.now()) {
                logout();
            } else {
                setUser({
                    username: decoded.username,
                    token: token
                });

                const timeout = setTimeout(() => {
                    logout();
                }, decoded.exp * 1000 - Date.now());

                return () => clearTimeout(timeout);
            }
        }
    }, []);

    useEffect(() => {
        if (intervalRef.current) clearInterval(intervalRef.current); // Clear existing interval

        intervalRef.current = setInterval(() => {
            const token = localStorage.getItem('token');
            if (!token || jwtDecode(token).exp * 1000 < Date.now()) {
                logout();
            }
        }, 60000); // Set a new interval every time user state changes

        return () => clearInterval(intervalRef.current); // Cleanup on component unmount
    }, [user]); // Dependency on user to restart the interval when the user logs in or out

    const login = (userData) => {
        localStorage.setItem('token', userData.token);
        setUser(userData);
    };

    // Function to set the logout callback
    const setLogoutCallback = (callback) => {
        logoutCallbackRef.current = callback;
    };

    const logout = (callback) => {
        localStorage.removeItem('token');
        setUser(null);
        clearInterval(intervalRef.current); // Ensure the interval is cleared on logout
	if (logoutCallbackRef.current) {
            logoutCallbackRef.current();  // Execute the logout callback if available
        }
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, setLogoutCallback }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
